import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Container from '../components/Container';
import Text from '../components/Text';
import config from '../config';

const Content = styled.div`
  height: 100%;
  width: 100%;

  > h1 {
    margin-bottom: ${config.sizes.spacing}px;
  }

  > h2 {
    margin-bottom: ${config.sizes.spacing}px;
  }

  > p {
    margin-bottom: ${config.sizes.spacing * 2}px;
  }
`;

const List = styled.ul`
  > li {
    margin: 0;
    padding: 0;
    padding-left: ${config.sizes.spacing}px;
  }
`;

const COVID = () => {
  return (
    <>
      <Helmet>
        <title>Excel Fitness Club | COVID-19</title>
      </Helmet>
      <Container>
        <Content>
          <Text as="h1" size="x-large" weight="bold">
            COVID-19 UPDATE:
          </Text>
          <Text as="h2" size="large" weight="bold">
            {'Reopening date: Monday, January 31st'}
          </Text>
          <Text as="p" size="medium">
            {'Ontario has announced that gyms are permitted to re-open.'}
          </Text>

          <Text as="h2" size="medium" weight="bold">
            {'Thank you for your support, see you soon!'}
          </Text>
        </Content>
      </Container>
    </>
  );
};

export default COVID;
